<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap" @contextmenu.prevent @drag.prevent>
        <CarrotTitle title="명함 상세"></CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div v-if="view.isLoaded==true">
                    <div class="businessCard-box">
                        <div class="bc-head">
                            <span class="company">{{ view.info.company_name }}</span>
                            <span class="name">{{ view.info.kname }}</span>
                            <p class="team">{{ view.info.part }}/{{ view.info.position }}</p>
                        </div>
                        <dl class="bc-info">
                            <div class="inbox">
                                <dt>M</dt><dd>{{ view.info.mobile }}</dd>
                            </div>
                            <div class="inbox">
                                <dt>E</dt><dd>{{ view.info.email }}</dd>
                            </div>
                            <div class="inbox">
                                <dt>T</dt><dd>{{ view.info.tel }}</dd>
                            </div>
                            <div class="inbox">
                                <dt>F</dt><dd>{{ view.info.fax }}</dd>
                            </div>
                            <div class="inbox">
                                <dt>A</dt><dd>{{ view.info.addr + ' ' + view.info.addr_sub }}</dd>
                            </div>
                        </dl>
                    </div>

                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>등록일</th>
                                <td colspan="3">{{ view.info.wdate }}</td>
                            </tr>
                            <tr>
                                <th>등록자</th>
                                <td colspan="3">
                                        {{ view.info.hq_ename }}({{ view.info.hq_kname }})
                                </td>
                            </tr>
                            <tr>
                                <th>담당자 정보</th>
                                <td colspan="3">
                                    <div>
                                        <label v-for="(irow, i) in view.mtype_list" :key="i"><input type="checkbox" v-model="view.info.mtype" :value="irow.code" onclick="return false;"><span class="ml-5 mr-20"> {{ irow.name }}</span></label>
                                    </div>
                                    <div class="mt-5">
                                        <span class="mr-20">VIP</span>
                                        <label><input type="radio" v-model="view.info.is_vip" value="Y" onclick="return false;"><span class="ml-5 mr-20"> Y</span></label>
                                        <label><input type="radio" v-model="view.info.is_vip" value="N" onclick="return false;"><span class="ml-5 mr-20"> N</span></label>
                                    </div>
                                    <div class="mt-5">
                                        <span class="mr-20">메인 담당자</span>
                                        <label><input type="radio" v-model="view.info.is_main" value="Y" onclick="return false;"><span class="ml-5 mr-20"> Y</span></label>
                                        <label><input type="radio" v-model="view.info.is_main" value="N" onclick="return false;"><span class="ml-5 mr-20"> N</span></label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>사보발송 여부</th>
                                <td colspan="3">{{ view.info.is_newsletter }}</td>
                            </tr>
                            <tr>
                                <th>사보발송 주소</th>
                                <td colspan="3">{{ view.info.nl_zipcode + ' ' + view.info.nl_addr + ' ' + view.info.nl_addr_sub }}</td>
                            </tr>
                            <tr>
                                <th>DM 발송 대상</th>
                                <td colspan="3">{{ view.info.is_dm }}</td>
                            </tr>
                            <tr>
                                <th>재직 여부</th>
                                <td colspan="3">{{ view.info.is_work=='Y' ? '재직중' : '퇴사' }}</td>
                            </tr>
                            <tr>
                                <th>담당자 특이사항</th>
                                <td colspan="3" class="preline">{{ view.info.special_memo }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <router-link :to="{ name:'customerManagement-businessCardList' }"><button @click="view.goList" class="btn-default float-left mt-30 mr-10">목록</button></router-link>
                    <button v-if="view.info.is_del_able=='Y'" @click="view.doDelete" class="btn-default float-left mt-30">삭제</button>
                    <router-link :to="{ name:'customerManagement-businessCardMod-idx', params: { idx:view.idx } }"><button class="btn-default float-right mt-30">수정</button></router-link>
                    <div class="clear"></div>

                    <h3 class="block-title mt-40 mb-20">변경이력</h3>
                    <table class="table-col">
                        <colgroup>
                            <col width="*">
                            <col width="180">
                            <col width="180">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>변경사항</th>
                                <th>변경일</th>
                                <th>변경 매니저</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in view.list" :key="i">
                                <td>{{ irow.memo }}</td>
                                <td>{{ irow.createdate }}</td>
                                <td>{{ irow.ename }}({{ irow.kname }})</td>
                            </tr>
                            <tr v-if="view.total==0">
                                <td colspan="3">변경 이력이 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'


export default {
    layout:"customerManagement",
    components: {
    },
    setup() {
        const router = new useRouter();
        const route  = new useRoute();
        const toast = useToast()

        const view = reactive({
            isLoaded   : false,
            mtype_list : [],

            idx : 0,
            info : {},

            page : 1, 
            rows : 10,
            list : {}, total : 0,

            doSearch : () => {
                if( view.idx == 0 ) return;

                let params = {
                    page : view.page,
                    rows : view.rows,
                    idx  : view.idx
                };

                axios.get("/rest/customermgr/getBizcardHistory", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.total = res.data.total;
                        view.list  = res.data.list;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doDelete : () => {
                Swal.fire({
                    title:'삭제하시겠습니까?',
                    showCancelButton: true
                }).then((res) => {
                    if (res.isConfirmed) {
                        let params = {
                            idx : view.idx
                        };
                        axios.post("/rest/customermgr/delBizcard", params).then((res) => {
                            if( res.data.err == 0 ){
                                router.push({
                                    name : "customerManagement-businessCardList"
                                });
                            } else {
                                if(res.data.err_msg) toast.error(res.data.err_msg);
                            }
                        });
                    }
                });
            },

            doSearchInfo : () => {
                let params = {
                    idx : view.idx
                };

                axios.get("/rest/customermgr/getBizcardInfo", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.info = res.data;

                        view.doSearch();
                        view.isLoaded = true;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            getMtypeList : () => {
                let params = { };

                axios.get("/rest/customermgr/getMtypeList", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.mtype_list = res.data.list;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            }
        });

        onMounted(() => {
            // Mounted
            view.idx = route.params.idx?route.params.idx:0;

            if ( !view.idx ) {
                router.back(-1);
            }

            view.getMtypeList();

            view.doSearchInfo();
        });

        return {view};
    }
}
</script>

<style lang="scss" scoped>
.con-wrap {
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
}
</style>